import React from "react";

import { styled } from "@mui/material/styles";

import { Typography } from "@mui/material";

import styledOri from "styled-components";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import BranchBackground from "../../images/branches/branch-background-3.svg";

import UtterBerryWhite from "../../images/utterberry-title-white.svg";
import HexBackground from "../../images/utterberry-paints/hex-background-5.svg";

const PREFIX = "Landing";

const classes = {
  root: `${PREFIX}-root`,
  sensorImage: `${PREFIX}-sensorImage`,
  content: `${PREFIX}-content`,
  textContainer: `${PREFIX}-textContainer`,
  borderlessContainer: `${PREFIX}-borderlessContainer`,
  titleAndDescription: `${PREFIX}-titleAndDescription`,
  titleContainer: `${PREFIX}-titleContainer`,
  titleImage: `${PREFIX}-titleImage`,
  title: `${PREFIX}-title`,
  whiteTitle: `${PREFIX}-whiteTitle`,
  description: `${PREFIX}-description`,
  branchesContainer: `${PREFIX}-branchesContainer`,
  branches: `${PREFIX}-branches`,
  hexContainer: `${PREFIX}-hexContainer`,
  hexImage: `${PREFIX}-hexImage`,
};

const Root = styled("div")(({ theme }) => ({
  height: "calc(100vh - 65px)",
  width: "100%",
  background: "white",
  padding: 30,
  position: "relative",
  zIndex: 11,
  [theme.breakpoints.between("sm", "md")]: {
    padding: 20,
  },
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 57px)",
  },
  "@media (max-height: 500px)": {
    height: "calc(100vh - 49px)",
  },

  [`& .${classes.sensorImage}`]: {
    width: "70%",
    maxWidth: 300,
    maxHeight: 300,
    objectFit: "contain",
    minWidth: 100,
  },

  [`& .${classes.content}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    width: "100%",
    height: "100%",
    padding: 20,
    zIndex: 1,
    position: "relative",
    [theme.breakpoints.between("sm", "md")]: {
      padding: 15,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 7,
    },
  },

  [`& .${classes.textContainer}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px",
    maxWidth: 520,
    background: "white",
    borderRadius: "10px",
    border: "1px solid #540096",
    boxShadow: "0px 0px 29px -2px rgba(0,0,0,0.44)",
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: 320,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 280,
    },
  },

  [`& .${classes.borderlessContainer}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px",
    maxWidth: 520,
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: 320,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 280,
    },
  },

  [`& .${classes.titleAndDescription}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },

  [`& .${classes.titleContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.titleImage}`]: {
    margin: "0px 8px",
    height: "56px",
    marginBottom: 4,
    [theme.breakpoints.down("md")]: {
      height: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
      margin: "0px 5px 3px 5px",
    },
  },

  [`& .${classes.title}`]: {
    color: "#540096",
    paddingBottom: 5,
    fontWeight: "600",
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },

  [`& .${classes.whiteTitle}`]: {
    color: "#fff",
    paddingBottom: 5,
    fontWeight: "600",
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },

  [`& .${classes.description}`]: {
    padding: "25px 20px 0px 20px",
    textAlign: "center",
  },

  [`& .${classes.branchesContainer}`]: {
    width: "100%",
    height: 30,
    position: "relative",
  },

  [`& .${classes.branches}`]: {
    backgroundImage: `url("${BranchBackground}")`,
    width: "100%",
    backgroundSize: "140px 50px",
    backgroundRepeat: "repeat-x",
    backgroundPosition: "top",
    height: 50,
    borderTop: "solid 2px #540096",
    position: "absolute",
    left: 0,
    top: 0,
  },

  // HEX BACKGROUND
  [`& .${classes.hexContainer}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "95%",
    width: "100%",
    zIndex: 0,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.hexImage}`]: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.between("sm", "md")]: {
      width: "130%",
      height: "130%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "190%",
      height: "190%",
    },
  },
}));

const WhiteTitle = styledOri(Typography)`
  color: #fff;
  padding-bottom: 5px;
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 30px;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    font-size: 38px;
  }
`;

const SensorImage = ({ src, delay, style }) => {
  const gImage = getImage(src);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <GatsbyImage
        image={gImage}
        className={classes.sensorImage}
        style={{
          ...style,
        }}
        objectFit={"contain"}
        alt={`UtterBerry Sensor Image`}
      />
    </div>
  );
};

const Landing = (props) => {
  return (
    <Root>
      <div className={classes.hexContainer}>
        <img
          src={HexBackground}
          className={classes.hexImage}
          alt={"Hex Panel"}
        />
      </div>
      <div className={classes.content}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <SensorImage src={props.images.sensorLeft} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <SensorImage src={props.images.sensorBottomRight} delay={50} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <SensorImage src={props.images.sensorBottom} delay={100} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <SensorImage
            src={props.images.sensorLeftTop}
            delay={250}
            style={{
              transform: "scale(0.8)",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <SensorImage src={props.images.sensorTop} delay={200} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <SensorImage src={props.images.sensorRightTop} delay={150} />
        </div>
      </div>

      <div className={classes.titleAndDescription}>
        <div className={classes.borderlessContainer}>
          <span className={classes.titleContainer}>
            <WhiteTitle variant={"h2"}>The</WhiteTitle>
            <img
              src={UtterBerryWhite}
              className={classes.titleImage}
              alt={"UtterBerry"}
            />
            <WhiteTitle variant={"h2"}>Sensor</WhiteTitle>
          </span>
        </div>
      </div>
    </Root>
  );
};

export default Landing;
