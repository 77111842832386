import React, { Suspense, lazy, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Fade, Zoom, useMediaQuery, useTheme } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { StaticImage } from "gatsby-plugin-image";

// section branches
import MovementBranch from "../../images/spec-images/movement.svg";
import EnvironmentalBranch from "../../images/spec-images/environmental.svg";
import LeakBranch from "../../images/spec-images/leak.svg";
import CrowdBranch from "../../images/spec-images/crowd.svg";
import LoadingIcon from "./loading-icon";

const Sensor3D = lazy(() => import("./3d-sensor"));

const PREFIX = "SensorSpecs";

const classes = {
  root: `${PREFIX}-root`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  sensorInformationHolder: `${PREFIX}-sensorInformationHolder`,
  trackImage: `${PREFIX}-trackImage`,
  sectionContainer: `${PREFIX}-sectionContainer`,
  backgroundImage: `${PREFIX}-backgroundImage`,
  card: `${PREFIX}-card`,
  cardTitleContainer: `${PREFIX}-cardTitleContainer`,
  cardValueContainer: `${PREFIX}-cardValueContainer`,
  singleCardValueContainer: `${PREFIX}-singleCardValueContainer`,
  cardContainer: `${PREFIX}-cardContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  rightSectionTitle: `${PREFIX}-rightSectionTitle`,
  rightTitle: `${PREFIX}-rightTitle`,
  backshotContainer: `${PREFIX}-backshotContainer`,
  backshotImage: `${PREFIX}-backshotImage`,
  fadedBackshotImage: `${PREFIX}-fadedBackshotImage`,
  envBackshotImage: `${PREFIX}-envBackshotImage`,
  technicalTopArea: `${PREFIX}-technicalTopArea`,
  technicalBottomArea: `${PREFIX}-technicalBottomArea`,
  movementTrackImage: `${PREFIX}-movementTrackImage`,
  movementTL: `${PREFIX}-movementTL`,
  movementTR: `${PREFIX}-movementTR`,
  movementBL: `${PREFIX}-movementBL`,
  movementBR: `${PREFIX}-movementBR`,
  environmentTrackImage: `${PREFIX}-environmentTrackImage`,
  environmentTL: `${PREFIX}-environmentTL`,
  environmentTR: `${PREFIX}-environmentTR`,
  environmentBL: `${PREFIX}-environmentBL`,
  environmentBR: `${PREFIX}-environmentBR`,
  leakTrackImage: `${PREFIX}-leakTrackImage`,
  leakL: `${PREFIX}-leakL`,
  leakTR: `${PREFIX}-leakTR`,
  leakBR: `${PREFIX}-leakBR`,
  crowdTrackImage: `${PREFIX}-crowdTrackImage`,
  crowdTR: `${PREFIX}-crowdTR`,
  crowdBL: `${PREFIX}-crowdBL`,
  connectTrackImage: `${PREFIX}-connectTrackImage`,
  connectTL: `${PREFIX}-connectTL`,
  connectBR: `${PREFIX}-connectBR`,
  loadingIcon: `${PREFIX}-loadingIcon`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  position: "relative",

  [`& .${classes.whiteSpace}`]: {
    width: "100%",
    height: "calc(100vh - 65px)",
  },

  [`& .${classes.sensorInformationHolder}`]: {
    position: "sticky",
    top: 65,
    width: "100vw",
    height: "calc(100vh - 65px)",
  },

  [`& .${classes.trackImage}`]: {
    position: "absolute",
    left: "50%",
    top: "50%",
    width: "70%",
    height: "70%",
    objectFit: "contain",
    zIndex: 1,
    transform: "translate(-50%, -50%)",
    maxWidth: "1050px",
  },

  [`& .${classes.sectionContainer}`]: {
    position: "absolute",
    left: "0px",
    top: 0,
    width: "100%",
    height: "100%",
    padding: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    "@media(max-height: 875px)": {
      padding: "10px",
    },
  },

  [`& .${classes.backgroundImage}`]: {
    position: "relative",
    width: "100%",
    height: "80%",
    objectFit: "contain",
    zIndex: 1,
  },

  [`& .${classes.card}`]: {
    display: "flex",
    flexDirection: "column",
    padding: 25,
    justifyContent: "center",
    alignItems: "center",
    border: "5px solid #540096",
    borderRadius: 10,
    boxShadow: "0px 0px 33px -1px rgba(84,0,150,1)",
    width: "auto",
    background: "white",
    [theme.breakpoints.between("sm", "md")]: {
      padding: 14,
      minWidth: "160px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 6,
      minWidth: "80px",
      minHeight: "60px",
    },
  },

  [`& .${classes.cardTitleContainer}`]: {
    marginBottom: 10,
    padding: 10,
    fontWeight: "600",
    [theme.breakpoints.between("sm", "md")]: {
      padding: 8,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },

  [`& .${classes.cardValueContainer}`]: {
    color: "#540096",
  },

  [`& .${classes.singleCardValueContainer}`]: {
    color: "#540096",
    fontWeight: "600",
    margin: 10,
    [theme.breakpoints.between("sm", "md")]: {
      margin: 5,
    },
    [theme.breakpoints.down("sm")]: {
      margin: 5,
    },
  },

  [`& .${classes.cardContainer}`]: {
    width: "100%",
    height: "100%",
    maxWidth: "1050px",
    zIndex: 2,
    maxHeight: "770px",
  },

  [`& .${classes.sectionTitle}`]: {
    color: "#540096",
    paddingLeft: 10,
    borderLeft: "3px solid #540096",
    position: "sticky",
    top: 75,
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 27,
    },
  },

  [`& .${classes.rightSectionTitle}`]: {
    color: "#540096",
    paddingRight: 10,
    borderRight: "3px solid #540096",
  },

  [`& .${classes.rightTitle}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.backshotContainer}`]: {
    padding: 20,
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "100%",
    [theme.breakpoints.between("sm", "md")]: {
      padding: 10,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },

  [`& .${classes.backshotImage}`]: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "50%",
    zIndex: 0,
    [theme.breakpoints.between("sm", "md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  [`& .${classes.fadedBackshotImage}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "50%",
    zIndex: 0,
    opacity: 0.1,
    transform: "scaleX(-1)",
    [theme.breakpoints.between("sm", "md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  [`& .${classes.envBackshotImage}`]: {
    [theme.breakpoints.between("sm", "md")]: {
      bottom: 40,
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
    },
  },

  // TECHNICAL BOXES
  [`& .${classes.technicalTopArea}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
      paddingBottom: "112px",
    },
  },

  [`& .${classes.technicalBottomArea}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "112px",
      alignItems: "flex-start",
    },
  },

  // MOVEMENT
  [`& .${classes.movementTrackImage}`]: {
    maxWidth: "870px",
    width: "90%",
    height: "90%",
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "580px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.movementTL}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: "80px",
      paddingBottom: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      alignItems: "flex-end",
      padding: "0px 10px 80px 10px",
    },
    "@media(max-height: 875px)": {
      paddingBottom: "110px",
    },
    "@media(max-height: 700px)": {
      alignItems: "flex-start",
    },
  },

  [`& .${classes.movementTR}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: "20px",
    [theme.breakpoints.between("sm", "md")]: {
      paddingBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px 80px 10px",
    },
  },

  [`& .${classes.movementBL}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "20px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-start",
      alignItems: "center",
      paddingBottom: "100px",
      paddingTop: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "90px 10px 0px 10px",
    },
    "@media(max-height: 875px)": {
      paddingTop: "110px",
    },
    "@media(max-height: 740px)": {
      alignItems: "flex-end",
    },
  },

  [`& .${classes.movementBR}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "20px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-end",
      alignItems: "center",
      paddingBottom: "110px",
      paddingTop: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "90px 10px 0px 10px",
    },
    "@media(max-height: 875px)": {
      paddingTop: "105px",
    },
    "@media(max-height: 700px)": {
      alignItems: "flex-end",
    },
  },

  // ENVIRONMENT
  [`& .${classes.environmentTrackImage}`]: {
    maxWidth: "870px",
    width: "90%",
    height: "90%",
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "580px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.environmentTL}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: "140px",
      paddingLeft: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-end",
      padding: "0px 10px 80px 10px",
    },
    "@media(max-height: 700px)": {
      padding: "0px 10px 80px 10px",
    },
  },

  [`& .${classes.environmentTR}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "60px",
    [theme.breakpoints.between("sm", "md")]: {
      paddingBottom: "70px",
      paddingRight: "10px",
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-end",
      padding: "0px 10px 80px 10px",
    },
    "@media(max-height: 700px)": {
      padding: "0px 10px 60px 10px",
    },
  },

  [`& .${classes.environmentBL}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: "30px",
    paddingLeft: "10px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingTop: "80px",
      paddingLeft: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "90px 10px 0px 10px",
    },
    "@media(max-height: 700px)": {
      padding: "80px 0px 0px 20px",
    },
  },

  [`& .${classes.environmentBR}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "80px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-end",
      alignItems: "flex-start",
      paddingTop: "60px",
      paddingRight: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "90px 10px 0px 10px",
    },
    "@media(max-height: 700px)": {
      padding: "20px 20px 0px 10px",
    },
  },

  // LEAK
  [`& .${classes.leakTrackImage}`]: {
    maxWidth: "870px",
    width: "90%",
    height: "90%",
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "580px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.leakL}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gridArea: "left",
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      alignItems: "flex-end",
      padding: "0px 10px 165px 10px",
    },
    "@media(max-height: 700px)": {
      paddingLeft: "60px",
    },
  },

  [`& .${classes.leakTR}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gridArea: "topright",
    paddingBottom: "75px",
    [theme.breakpoints.between("sm", "md")]: {
      paddingBottom: "120px",
      paddingRight: "10px",
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-end",
      padding: "0px 10px 95px 10px",
    },
    "@media(max-height: 700px)": {
      alignItems: "flex-start",
      paddingTop: "10px",
    },
  },

  [`& .${classes.leakBR}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gridArea: "bottomright",
    paddingTop: "75px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-end",
      alignItems: "flex-start",
      paddingTop: "120px",
      paddingRight: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "80px 10px 0px 10px",
    },
    "@media(max-height: 700px)": {
      alignItems: "flex-end",
      paddingBottom: "10px",
    },
  },

  // CROWDS
  [`& .${classes.crowdTrackImage}`]: {
    maxWidth: "870px",
    width: "90%",
    height: "90%",
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "580px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.crowdTR}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gridArea: "topright",
    paddingTop: "30px",
    [theme.breakpoints.between("sm", "md")]: {
      paddingBottom: "80px",
      paddingRight: "10px",
      paddingTop: "0px",
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-end",
      padding: "0px 10px 95px 10px",
    },
    "@media(max-height: 700px)": {
      paddingTop: "0px",
      alignItems: "center",
    },
    "@media(max-height: 600px)": {
      paddingTop: "10px",
      alignItems: "flex-start",
    },
  },

  [`& .${classes.crowdBL}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gridArea: "bottomleft",
    paddingBottom: "40px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingTop: "70px",
      paddingLeft: "10px",
      paddingBottom: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "80px 10px 0px 10px",
    },
    "@media(max-height: 700px)": {
      paddingBottom: "0px",
      paddingTop: "20px",
      alignItems: "center",
    },
    "@media(max-height: 600px)": {
      paddingBottom: "20px",
      alignItems: "flex-end",
    },
  },

  // CONNECTIVITY
  [`& .${classes.connectTrackImage}`]: {
    maxWidth: "870px",
    width: "90%",
    height: "90%",
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "580px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.connectTL}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gridArea: "topleft",
    paddingTop: "50px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-start",
      alignItems: "flex-end",
      paddingBottom: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      alignItems: "flex-end",
      padding: "0px 10px 80px 10px",
    },
    "@media(max-height: 700px)": {
      paddingBottom: "30px",
      paddingTop: "0px",
      alignItems: "center",
    },
  },

  [`& .${classes.connectBR}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gridArea: "bottomright",
    paddingBottom: "40px",
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "flex-end",
      alignItems: "flex-start",
      paddingTop: "80px",
      paddingBottom: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "80px 10px 0px 10px",
    },
    "@media(max-height: 700px)": {
      paddingTop: "30px",
      paddingBottom: "0px",
      alignItems: "center",
    },
  },

  [`& .${classes.loadingIcon}`]: {
    objectFit: "contain",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#540096",
    padding: "20px",
    borderRadius: "20px",
    zIndex: 10,
  },
}));

const Card = ({ title, value }) => {
  return (
    <div className={classes.card}>
      {!!title && (
        <div className={classes.cardTitleContainer}>
          <Typography>{title}</Typography>
        </div>
      )}
      <div className={classes.cardValueContainer}>
        <Typography>{value}</Typography>
      </div>
    </div>
  );
};

const CardSingle = ({ value }) => {
  return (
    <div className={classes.card}>
      <div className={classes.singleCardValueContainer}>
        <Typography>{value}</Typography>
      </div>
    </div>
  );
};

const Technical = React.memo(({ inView }) => {
  return (
    <Zoom in={inView}>
      <div className={classes.sectionContainer}>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        >
          <StaticImage
            src={"../../images/utterberry-paints/paint-background-c.webp"}
            className={`${classes.backgroundImage} `}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Technical Background"}
            style={{
              minWidth: 500,
            }}
            width={352}
            height={285}
          />
        </div>
        <div
          className={classes.cardContainer}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
          }}
        >
          <div className={classes.technicalTopArea}>
            <Card title={"Dimensions (mm)"} value={"31 x 56 x 15"} />
          </div>
          <div className={classes.technicalTopArea}>
            <Card title={"Total Weight"} value={"15g"} />
          </div>
          <div className={classes.technicalBottomArea}>
            <Card
              title={"Battery Life"}
              value={"1 – 3 years depending upon use"}
            />
          </div>
          <div className={classes.technicalBottomArea}>
            <Card title={"Waterproof"} value={"IP68 rating"} />
          </div>
        </div>
      </div>
    </Zoom>
  );
});

const Movement = React.memo(({ inView }) => {
  return (
    <Zoom in={inView}>
      <div className={classes.sectionContainer}>
        <img
          src={MovementBranch}
          className={`${classes.trackImage} ${classes.movementTrackImage}`}
          alt={"Movement Tracks"}
        />
        <div
          className={classes.cardContainer}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
          }}
        >
          <div className={classes.movementTL}>
            <CardSingle
              value={"Displacement (x,y,z) to sub-millimetre accuracy"}
            />
          </div>
          <div className={classes.movementTR}>
            <CardSingle value={"Peak-to-peak velocity (x,y,z)"} />
          </div>
          <div className={classes.movementBL}>
            <CardSingle value={"Tilt of Sensor (x,y,z)"} />
          </div>
          <div className={classes.movementBR}>
            <CardSingle value={"Acceleration (x,y,z)"} />
          </div>
        </div>
      </div>
    </Zoom>
  );
});

const Environmental = React.memo(({ inView }) => {
  return (
    <Zoom in={inView}>
      <div className={classes.sectionContainer}>
        <img
          src={EnvironmentalBranch}
          className={`${classes.trackImage} ${classes.environmentTrackImage}`}
          alt={"Environment Tracks"}
        />
        <div
          className={classes.cardContainer}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
          }}
        >
          <div className={classes.environmentTL}>
            <CardSingle value={"Temperature"} />
          </div>
          <div className={classes.environmentTR}>
            <CardSingle value={"Humidity"} />
          </div>
          <div className={classes.environmentBL}>
            <CardSingle value={"Pressure"} />
          </div>
          <div className={classes.environmentBR}>
            <CardSingle value={"Luminosity"} />
          </div>
        </div>
      </div>
    </Zoom>
  );
});

const Leak = React.memo(({ inView }) => {
  return (
    <Zoom in={inView}>
      <div className={classes.sectionContainer}>
        <img
          src={LeakBranch}
          className={`${classes.trackImage} ${classes.leakTrackImage}`}
          alt={"Leak Tracks"}
        />
        <div
          className={classes.cardContainer}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            gridTemplateAreas: `
                "left topright"
                "left bottomright"
            `,
          }}
        >
          <div className={classes.leakL}>
            <CardSingle value={"Fluid Flow"} />
          </div>
          <div className={classes.leakTR}>
            <CardSingle value={"Leak Detection"} />
          </div>
          <div className={classes.leakBR}>
            <CardSingle value={"Leak Prediction"} />
          </div>
        </div>
      </div>
    </Zoom>
  );
});

const Crowd = React.memo(({ inView }) => {
  return (
    <Zoom in={inView}>
      <div className={classes.sectionContainer}>
        <img
          src={CrowdBranch}
          className={`${classes.trackImage} ${classes.movementTrackImage}`}
          alt={"Crowd Tracks"}
        />
        <div
          className={classes.cardContainer}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            gridTemplateAreas: `
                ". topright"
                "bottomleft ."
            `,
          }}
        >
          <div className={classes.crowdBL}>
            <CardSingle value={"Sense and Count"} />
          </div>
          <div className={classes.crowdTR}>
            <CardSingle value={"Differentiate animals, humans, and vehicles"} />
          </div>
        </div>
      </div>
    </Zoom>
  );
});

const Connectivity = React.memo(({ inView }) => {
  return (
    <Zoom in={inView}>
      <div className={classes.sectionContainer}>
        <img
          src={CrowdBranch}
          className={`${classes.trackImage} ${classes.connectTrackImage}`}
          style={{
            transform: "scaleY(-1) translate(-50%, 50%)",
          }}
          alt={"Crowd Tracks"}
        />
        <div
          className={classes.cardContainer}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            gridTemplateAreas: `
                "topleft ."
                ". bottomright"
            `,
          }}
        >
          <div className={classes.connectTL}>
            <CardSingle value={"3G, 4G, 5G Connections"} />
          </div>
          <div className={classes.connectBR}>
            <CardSingle value={"WiFi, Ethernet Connections"} />
          </div>
        </div>
      </div>
    </Zoom>
  );
});

const SensorSpecs = () => {
  const isSSR = typeof window === "undefined";

  const { ref: technicalRef, inView: technicalInView } = useInView({
    threshold: 0.5,
  });

  const { ref: movementRef, inView: movementInView } = useInView({
    threshold: 0.5,
  });

  const { ref: environmentalRef, inView: environmentalInView } = useInView({
    threshold: 0.5,
  });

  const { ref: leakRef, inView: leakInView } = useInView({
    threshold: 0.5,
  });

  const { ref: crowdRef, inView: crowdInView } = useInView({
    threshold: 0.5,
  });

  const { ref: connectivityRef, inView: connectivityInView } = useInView({
    threshold: 0.5,
  });

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);

  return (
    <Root>
      <div
        className={classes.whiteSpace}
        ref={technicalRef}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          padding: 20,
          background: "white",
        }}
      >
        <Fade in={technicalInView}>
          <Typography variant={"h2"} className={classes.sectionTitle}>
            TECHNICAL SPECIFICATIONS
          </Typography>
        </Fade>
      </div>
      <div
        className={classes.sensorInformationHolder}
        style={{
          zIndex: 1,
        }}
      >
        {loading && <LoadingIcon />}
        {!isSSR && (
          <Suspense fallback={null}>
            <Sensor3D
              includeDimensions={technicalInView}
              tl={environmentalInView}
              tr={movementInView}
              br={leakInView}
              bl={crowdInView}
              size={md ? "medium" : sm ? "small" : "large"}
              onLoading={(loading) => {
                setLoading(loading);
              }}
            />
          </Suspense>
        )}
        <Technical inView={technicalInView} />
        <Movement inView={movementInView} />
        <Environmental inView={environmentalInView} />
        <Leak inView={leakInView} />
        <Crowd inView={crowdInView} />
        <Connectivity inView={connectivityInView} />
      </div>
      <div
        style={{
          width: "100%",
          height: "65px",
          background: "white",
        }}
      ></div>
      <div
        className={classes.whiteSpace}
        style={{
          background: "white",
          position: "relative",
          zIndex: 0,
        }}
        ref={movementRef}
      >
        <div
          style={{
            position: "sticky",
            top: 75,
            zIndex: 1,
            padding: "0px 20px",
          }}
        >
          <Fade in={movementInView}>
            <Typography
              variant={"h2"}
              className={classes.sectionTitle}
              style={{
                zIndex: 1,
              }}
            >
              REAL-TIME MOVEMENT CAPABILITIES
            </Typography>
          </Fade>
        </div>
        <div className={classes.backshotContainer}>
          <StaticImage
            src={"../../images/spec-images/underground-with-colour.webp"}
            className={classes.backshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Movement Background"}
            width={456}
            height={368}
          />
          <StaticImage
            src={"../../images/spec-images/underground-with-colour.webp"}
            className={classes.fadedBackshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Movement Background Reverse"}
            width={456}
            height={368}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "65px",
          background: "white",
        }}
      ></div>
      <div
        className={classes.whiteSpace}
        style={{
          background: "white",
          position: "relative",
          zIndex: 0,
        }}
        ref={environmentalRef}
      >
        <div
          style={{
            position: "sticky",
            top: 75,
            zIndex: 1,
            padding: "0px 20px",
          }}
        >
          <Fade in={environmentalInView}>
            <Typography variant={"h2"} className={classes.sectionTitle}>
              REAL-TIME ENVIRONMENTAL CAPABILITIES
            </Typography>
          </Fade>
        </div>
        <div className={classes.backshotContainer}>
          <StaticImage
            src={"../../images/spec-images/bridge-with-colour.webp"}
            className={classes.backshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Environment Background"}
            width={580}
            height={240}
          />
          <StaticImage
            src={"../../images/spec-images/bridge-with-colour.webp"}
            className={classes.fadedBackshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Environment Background Reverse"}
            width={580}
            height={240}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "65px",
          background: "white",
        }}
      ></div>
      <div
        className={classes.whiteSpace}
        style={{
          background: "white",
          position: "relative",
          zIndex: 0,
        }}
        ref={leakRef}
      >
        <div
          style={{
            position: "sticky",
            top: 75,
            zIndex: 1,
            padding: "0px 20px",
          }}
        >
          <Fade in={leakInView}>
            <Typography variant={"h2"} className={classes.sectionTitle}>
              LEAK DETECTION CAPABILITIES
            </Typography>
          </Fade>
        </div>
        <div className={classes.backshotContainer}>
          <StaticImage
            src={"../../images/spec-images/leak-background.webp"}
            className={classes.backshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Leak Background"}
            width={575}
            height={455}
          />
          <StaticImage
            src={"../../images/spec-images/leak-background.webp"}
            className={classes.fadedBackshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Leak Background Reverse"}
            width={575}
            height={455}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "65px",
          background: "white",
        }}
      ></div>
      <div
        className={classes.whiteSpace}
        style={{
          background: "white",
          position: "relative",
          zIndex: 0,
        }}
        ref={crowdRef}
      >
        <div
          style={{
            position: "sticky",
            top: 75,
            zIndex: 1,
            padding: "0px 20px",
          }}
        >
          <Fade in={crowdInView}>
            <Typography variant={"h2"} className={classes.sectionTitle}>
              CROWD SENSING CAPABILITIES
            </Typography>
          </Fade>
        </div>
        <div className={classes.backshotContainer}>
          <StaticImage
            src={"../../images/spec-images/crowd-background.webp"}
            className={classes.backshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Crowd Background"}
            width={575}
            height={400}
          />
          <StaticImage
            src={"../../images/spec-images/crowd-background.webp"}
            className={classes.fadedBackshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Crowd Background Reverse"}
            width={575}
            height={400}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "65px",
          background: "white",
        }}
      ></div>
      <div
        className={classes.whiteSpace}
        style={{
          background: "white",
          position: "relative",
          zIndex: 0,
        }}
        ref={connectivityRef}
      >
        <div
          style={{
            position: "sticky",
            top: 75,
            zIndex: 1,
            padding: "0px 20px",
          }}
        >
          <Fade in={connectivityInView}>
            <Typography variant={"h2"} className={classes.sectionTitle}>
              NETWORK CONNECTIVITY
            </Typography>
          </Fade>
        </div>
        <div className={classes.backshotContainer}>
          <StaticImage
            src={"../../images/spec-images/connectivity.webp"}
            className={classes.backshotImage}
            objectFit={"contain"}
            style={{
              transform: "scaleX(-1)",
              right: 15,
            }}
            placeholder={"blurred"}
            alt={"UtterBerry Connectivity"}
            width={575}
            height={400}
          />
          <StaticImage
            src={"../../images/spec-images/connectivity.webp"}
            className={classes.fadedBackshotImage}
            objectFit={"contain"}
            placeholder={"blurred"}
            style={{
              transform: "scaleX(1)",
              left: 15,
            }}
            alt={"UtterBerry Connectivity Reverse"}
            width={575}
            height={400}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "115px",
          background: "white",
        }}
      ></div>
    </Root>
  );
};

export default SensorSpecs;
