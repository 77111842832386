import React from "react";
import { styled, keyframes } from "@mui/material/styles";

const PREFIX = "LoadingIcon";

const rotateClockwise = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg)
  }
`;

const rotateAntiClockwise = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg)
  }
`;

const classes = {
  rotateClockwise: `${PREFIX}-rotateClockwise`,
  rotateAntiClockwise: `${PREFIX}-rotateAntiClockwise`,
};

const Root = styled("div")(({ theme }) => ({
  objectFit: "contain",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#540096",
  padding: "20px",
  borderRadius: "20px",
  zIndex: 10,

  [`& .${classes.rotateClockwise}`]: {
    animationName: rotateClockwise,
    animationDuration: "8s",
    animationIterationCount: "infinite",
    transformOrigin: "50% 50%",
  },

  [`& .${classes.rotateAntiClockwise}`]: {
    animationName: rotateAntiClockwise,
    animationDuration: "8s",
    animationIterationCount: "infinite",
    transformOrigin: "50% 50%",
  },
}));

const LoadingIcon = () => {
  return (
    <Root>
      <svg height='100' width='100' className='icon-loading' viewBox='0 0 135 135'>
        <path
          d='M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z'
          fill='#fff'
          stroke='#fff'
          className={classes.rotateClockwise}
        ></path>
        <path
          d='M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z'
          fill='#fff'
          stroke='#fff'
          className={classes.rotateAntiClockwise}
        ></path>
      </svg>
    </Root>
  );
};

export default LoadingIcon;
