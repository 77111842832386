import * as React from "react";

import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// sections
import Landing from "../components/specifications-page/landing";
import SensorSpecs from "../components/specifications-page/sensor-specs";

const Root = styled.main`
  padding: 0px;
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 65px;

  @media (max-width: 600px) {
    margin-top: 57px;
  }

  @media (max-height: 500px) {
    margin-top: 49px;
  }
`;

// markup
const IndexPage = (props) => {
  return (
    <Root>
      <Landing images={props.data} />
      <SensorSpecs />
    </Root>
  );
};

export function Head() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>Specifications - UtterBerry</title>
      <meta
        name='description'
        content='Specifications - UtterBerry is a provider of Artificially Intelligent wireless sensor systems for the development of Smart Cities and the revolutionisation of sensing capabilities.'
      ></meta>
    </Helmet>
  );
}

export const pageQuery = graphql`
  query {
    sensorTop: file(
      relativePath: { eq: "sensor-images/sensor-top-removebg.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 250, height: 110)
      }
    }
    sensorLeftTop: file(
      relativePath: { eq: "sensor-images/sensor-left-top-removebg.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 232, height: 270)
      }
    }
    sensorBottom: file(
      relativePath: { eq: "sensor-images/sensor-bottom-removebg.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 250, height: 180)
      }
    }
    sensorRightTop: file(
      relativePath: { eq: "sensor-images/sensor-right-top-removebg.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 250, height: 200)
      }
    }
    sensorLeft: file(
      relativePath: { eq: "sensor-images/sensor-left-removebg.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 230, height: 210)
      }
    }
    sensorBottomRight: file(
      relativePath: { eq: "sensor-images/sensor-bottom-right-removebg.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 250, height: 200)
      }
    }
  }
`;

export default IndexPage;
